import { SectionHeader } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import ModuleMarkdown from '../ModuleMarkdown';
import { marginContainer, wrap } from './SectionHeaderModule.module.css';

export default function SectionHeaderModule({
  blobs,
  className,
  data,
  hasTinyMobileTitle,
  hideWavyLine,
  isLeftAligned,
  largeTitle
}) {
  const {
    buttonCtaCopy,
    buttonCtaTrackingClass,
    buttonCtaUrl,
    copy,
    hasMarginBottom,
    linkCtaCopy,
    linkCtaTrackingClass,
    linkCtaUrl,
    tagline,
    title
  } = data;

  const classes = classNames(className, wrap, {
    [marginContainer] : hasMarginBottom
  });
  return (
    <div className={classes}>
      {blobs}
      <SectionHeader
        buttonCtaCopy={buttonCtaCopy}
        buttonCtaTrackingClass={buttonCtaTrackingClass}
        buttonCtaUrl={buttonCtaUrl}
        className={className}
        hasTinyMobileTitle={hasTinyMobileTitle}
        hideWavyLine={hideWavyLine}
        isLeftAligned={isLeftAligned}
        largeTitle={largeTitle}
        linkCtaCopy={linkCtaCopy}
        linkCtaTrackingClass={linkCtaTrackingClass}
        linkCtaUrl={linkCtaUrl}
        tagline={tagline}
        title={title}
      >
        {copy && (<ModuleMarkdown>{copy.childMarkdownRemark.htmlAst}</ModuleMarkdown>)}
      </SectionHeader>
    </div>
  );
}

export const SectionHeaderDataPropsObject = {
  buttonCtaCopy          : PropTypes.string,
  buttonCtaTrackingClass : PropTypes.string,
  buttonCtaUrl           : PropTypes.string,
  copy                   : PropTypes.shape({
    childMarkdownRemark : PropTypes.shape({
      htmlAst : PropTypes.object
    })
  }),
  hasMarginBottom      : PropTypes.bool,
  linkCtaCopy          : PropTypes.string,
  linkCtaTrackingClass : PropTypes.string,
  linkCtaUrl           : PropTypes.string,
  tagline              : PropTypes.string,
  title                : PropTypes.string.isRequired
};

SectionHeaderModule.propTypes = {
  blobs              : PropTypes.node,
  className          : PropTypes.string,
  data               : PropTypes.shape(SectionHeaderDataPropsObject).isRequired,
  hasTinyMobileTitle : PropTypes.bool,
  hideWavyLine       : PropTypes.bool,
  isLeftAligned      : PropTypes.bool,
  largeTitle         : PropTypes.bool
};

SectionHeaderModule.defaultProps = {
  blobs              : null,
  className          : null,
  hasTinyMobileTitle : false,
  hideWavyLine       : false,
  isLeftAligned      : false,
  largeTitle         : false
};

export const SectionHeaderModuleData = graphql`
  fragment SectionHeaderModuleData on ContentfulComponentSectionHeader {
    buttonCtaCopy
    buttonCtaTrackingClass
    buttonCtaUrl
    copy {
      childMarkdownRemark {
        htmlAst
      }
    }
    hasMarginBottom
    linkCtaCopy
    linkCtaTrackingClass
    linkCtaUrl
    tagline
    title
}
`;
