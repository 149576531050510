import { FeaturedContents, FeaturedContentsWrap } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import CaseStudyFeaturedContentModule, { CaseStudyFeaturedContentDataProps } from '../CaseStudyFeaturedContentModule';
import FeaturedContentModule, { FeaturedContentDataProps } from '../FeaturedContentModule';
import { marginContainer } from './FeaturedContentsModule.module.css';

export default function FeaturedContentsModule({
  className,
  data
}) {
  const {
    body,
    caseStudyContents,
    contents,
    hasMarginBottom,
    hideWavyLine,
    primaryCtaCopy,
    primaryCtaTrackingClass,
    primaryCtaTrackingClassMobile,
    primaryCtaUrl,
    secondaryCtaCopy,
    secondaryCtaTrackingClass,
    secondaryCtaTrackingClassMobile,
    secondaryCtaUrl,
    tagline,
    title
  } = data;

  const classes = classNames(className, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <FeaturedContents
      body={body}
      className={classes}
      hideWavyLine={hideWavyLine}
      tagline={tagline}
      title={title}
    >
      <FeaturedContentsWrap
        primaryCTACopy={primaryCtaCopy}
        primaryCTATrackingClass={primaryCtaTrackingClass}
        primaryCTATrackingClassMobile={primaryCtaTrackingClassMobile}
        primaryCTAUrl={primaryCtaUrl}
        secondaryCTACopy={secondaryCtaCopy}
        secondaryCTATrackingClass={secondaryCtaTrackingClass}
        secondaryCTATrackingClassMobile={secondaryCtaTrackingClassMobile}
        secondaryCTAUrl={secondaryCtaUrl}
      >
        { caseStudyContents
          ? caseStudyContents.map((item) => (
            <CaseStudyFeaturedContentModule key={item.id} data={item} />
          ))
          : contents.map((item) => (
            <FeaturedContentModule key={item.id} data={item} />
          ))}
      </FeaturedContentsWrap>
    </FeaturedContents>
  );
}

export const FeaturedContentsDataPropsObject = {
  body                            : PropTypes.string,
  caseStudyContents               : PropTypes.arrayOf(CaseStudyFeaturedContentDataProps),
  contents                        : PropTypes.arrayOf(FeaturedContentDataProps),
  hasMarginBottom                 : PropTypes.bool,
  hideWavyLine                    : PropTypes.bool,
  primaryCtaCopy                  : PropTypes.string,
  primaryCtaTrackingClass         : PropTypes.string,
  primaryCtaTrackingClassMobile   : PropTypes.string,
  primaryCtaUrl                   : PropTypes.string,
  secondaryCtaCopy                : PropTypes.string,
  secondaryCtaTrackingClass       : PropTypes.string,
  secondaryCtaTrackingClassMobile : PropTypes.string,
  secondaryCtaUrl                 : PropTypes.string,
  tagline                         : PropTypes.string,
  title                           : PropTypes.string.isRequired
};

FeaturedContentsModule.propTypes = {
  className : PropTypes.string,
  data      : PropTypes.shape(FeaturedContentsDataPropsObject).isRequired
};

FeaturedContentsModule.defaultProps = {
  className : null
};

export const FeaturedContentsModuleData = graphql`
  fragment FeaturedContentsModuleData on ContentfulComponentFeaturedContents {
    body {
      body
    }
    caseStudyContents {
      ...CaseStudyFeaturedContentModuleData
    }
    contents {
      ...FeaturedContentModuleData
    }
    hasMarginBottom
    hideWavyLine
    primaryCtaCopy
    primaryCtaTrackingClass
    primaryCtaTrackingClassMobile
    primaryCtaUrl
    secondaryCtaCopy
    secondaryCtaTrackingClass
    secondaryCtaTrackingClassMobile
    secondaryCtaUrl
    tagline
    title
  }
`;
