// extracted by mini-css-extract-plugin
export var base = "ProTipModule-module--base--2H9Nb";
export var rightAlignedContainer = "ProTipModule-module--rightAlignedContainer--1weSp";
export var rightAlignedContainerSmall = "ProTipModule-module--rightAlignedContainerSmall--3xQ03";
export var leftAlignedContainer = "ProTipModule-module--leftAlignedContainer--3UBP0";
export var blogContainer = "ProTipModule-module--blogContainer--1-Bh8 ProTipModule-module--base--2H9Nb";
export var container = "ProTipModule-module--container--2_x9P ProTipModule-module--base--2H9Nb";
export var largeContainer = "ProTipModule-module--largeContainer--3h4bF ProTipModule-module--base--2H9Nb";
export var mobileSmallPadding = "ProTipModule-module--mobileSmallPadding--mUae2";
export var fullWidthMobile = "ProTipModule-module--fullWidthMobile--2DbhZ";
export var gridWithSidebar = "ProTipModule-module--gridWithSidebar--1yMBj";
export var noMaxWidth = "ProTipModule-module--noMaxWidth--226Pd";
export var wrap = "ProTipModule-module--wrap--3HrDf";
export var proTip = "ProTipModule-module--proTip--24S93 ProTipModule-module--container--2_x9P ProTipModule-module--base--2H9Nb ProTipModule-module--mobileSmallPadding--mUae2";
export var marginContainer = "ProTipModule-module--marginContainer--bxpVg";