import { FeaturedContent, useClient } from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

export default function CaseStudyFeaturedContentModule({ data }) {
  const {
    articlePreviewBody,
    articlePreviewCategory,
    articlePreviewCategoryUrl,
    articlePreviewCtaLinkTrackingClass,
    articlePreviewImageSrcFluid,
    articlePreviewPostUrl,
    articlePreviewTitle
  } = data;

  const pageSlug = useClient()
    && (window.location.pathname).replace(/\/|-/g, '');
  const displayTracking = pageSlug
    ? `track-${pageSlug}-${articlePreviewCtaLinkTrackingClass}`
    : `track-${articlePreviewCtaLinkTrackingClass}`;

  return (
    <FeaturedContent
      alt={articlePreviewTitle}
      copy={articlePreviewBody}
      ctaCopy="Read more"
      ctaTrackingClass={displayTracking}
      ctaUrl={articlePreviewPostUrl}
      imageSrcFluid={articlePreviewImageSrcFluid.fluid}
      tagline={articlePreviewCategory}
      taglineUrl={articlePreviewCategoryUrl}
      title={articlePreviewTitle}
    />
  );
}

export const CaseStudyFeaturedContentDataProps = PropTypes.shape({
  articlePreviewBody                 : PropTypes.string,
  articlePreviewCategory             : PropTypes.string,
  articlePreviewCategoryUrl          : PropTypes.string,
  articlePreviewCtaLinkTrackingClass : PropTypes.string,
  articlePreviewImageSrcFluid        : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number,
      sizes       : PropTypes.string,
      src         : PropTypes.string,
      srcSet      : PropTypes.string,
      srcSetWebp  : PropTypes.string,
      srcWebp     : PropTypes.string
    })
  }).isRequired,
  articlePreviewPostUrl : PropTypes.string.isRequired,
  articlePreviewTitle   : PropTypes.string.isRequired,
  id                    : PropTypes.string.isRequired
});

CaseStudyFeaturedContentModule.propTypes = {
  data : CaseStudyFeaturedContentDataProps.isRequired
};

export const CaseStudyFeaturedContentModuleData = graphql`
  fragment CaseStudyFeaturedContentModuleData on ContentfulComponentCaseStudyHero {
    articlePreviewBody
    articlePreviewCategory
    articlePreviewCategoryUrl
    articlePreviewCtaLinkTrackingClass
    articlePreviewImageSrcFluid {
      fluid(maxWidth: 270) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    articlePreviewPostUrl
    articlePreviewTitle
    id
  }
`;
