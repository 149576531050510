import {
  BlueSplats2,
  GreenBean,
  HalfYellowBall,
  PinkSquiggles,
  RedSwipe,
  YellowBallPinkBean,
  YellowSplash } from '@animoto/components';
import React from 'react';
import MediaQuery from 'react-responsive';

import {
  blueSplats2,
  greenBean,
  halfYellowBall,
  pinkSquiggles,
  redSwipe,
  yellowBallPinkBean,
  yellowSplash
} from './getBlobs.module.css';

export default function getBlobs(index) {
  const blobIndex = index % 4;
  if (blobIndex === 0) {
    return (
      <>
        <MediaQuery minWidth={768}>
          <YellowSplash className={yellowSplash} />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <BlueSplats2 className={blueSplats2} />
        </MediaQuery>
      </>
    );
  }

  if (blobIndex === 2) {
    return (
      <>
        <MediaQuery minWidth={768}>
          <RedSwipe className={redSwipe} />
          <YellowBallPinkBean className={yellowBallPinkBean} />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <PinkSquiggles className={pinkSquiggles} />
          <HalfYellowBall className={halfYellowBall} />
          <GreenBean className={greenBean} />
        </MediaQuery>
      </>
    );
  }

  return null;
}
