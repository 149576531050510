import { FeaturedContent } from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

export default function FeaturedContentModule({ data }) {
  const {
    alt,
    copy,
    ctaCopy,
    ctaTrackingClass,
    ctaUrl,
    imageSrcFluid,
    tagline,
    taglineUrl,
    taglineUrlTrackingClass,
    title
  } = data;
  return (
    <FeaturedContent
      alt={alt}
      copy={copy && copy.copy}
      ctaCopy={ctaCopy}
      ctaTrackingClass={ctaTrackingClass}
      ctaUrl={ctaUrl}
      imageSrcFluid={imageSrcFluid.fluid}
      tagline={tagline}
      taglineUrl={taglineUrl}
      taglineUrlTrackingClass={taglineUrlTrackingClass}
      title={title}
    />
  );
}

export const FeaturedContentDataProps = PropTypes.shape({
  alt  : PropTypes.string,
  copy : PropTypes.shape({
    copy : PropTypes.string
  }),
  ctaCopy          : PropTypes.string,
  ctaTrackingClass : PropTypes.string,
  ctaUrl           : PropTypes.string,
  id               : PropTypes.string.isRequired,
  imageSrcFluid    : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number,
      sizes       : PropTypes.string,
      src         : PropTypes.string,
      srcSet      : PropTypes.string,
      srcSetWebp  : PropTypes.string,
      srcWebp     : PropTypes.string
    })
  }).isRequired,
  tagline                 : PropTypes.string,
  taglineUrl              : PropTypes.string,
  taglineUrlTrackingClass : PropTypes.string,
  title                   : PropTypes.string.isRequired
});

FeaturedContentModule.propTypes = {
  data : FeaturedContentDataProps.isRequired
};

export const FeaturedContentsModuleData = graphql`
  fragment FeaturedContentModuleData on ContentfulComponentFeaturedContent {
    alt
    copy {
      copy
    }
    ctaCopy
    ctaUrl
    ctaTrackingClass
    id
    imageSrcFluid {
      fluid(maxWidth: 350) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    tagline
    taglineUrl
    taglineUrlTrackingClass
    title
    }
    `;
