import { ProTip } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import ModuleMarkdown from '../ModuleMarkdown';
import { marginContainer, proTip, wrap } from './ProTipModule.module.css';

export default function ProTipModule({ blobs, className, data, tipBlobsVersion }) {
  const {
    copy,
    ctaCopy,
    ctaTrackingClass,
    ctaUrl,
    hasMarginBottom,
    hideAssetBlobs,
    hideTipBlobs,
    imageAlt,
    imageSrcFluid,
    mobileCTACopy,
    mobileCTATrackingClass,
    mobileCTAUrl,
    tipCopy,
    tipTitle,
    title,
    tagline,
    videoPoster,
    videoUrl
  } = data;
  const classes = classNames(className, proTip);
  const divClasses = classNames(className, wrap, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <div className={divClasses}>
      {blobs}
      <ProTip
        className={classes}
        copy={<ModuleMarkdown>{copy.childMarkdownRemark.htmlAst}</ModuleMarkdown>}
        ctaCopy={ctaCopy}
        ctaTrackingClass={ctaTrackingClass}
        ctaUrl={ctaUrl}
        hideAssetBlobs={hideAssetBlobs}
        hideTipBlobs={hideTipBlobs}
        imageAlt={imageAlt}
        imageSrcFluid={imageSrcFluid && imageSrcFluid.fluid}
        mobileCTACopy={mobileCTACopy}
        mobileCTATrackingClass={mobileCTATrackingClass}
        mobileCTAUrl={mobileCTAUrl}
        tagline={tagline}
        tipBlobsVersion={tipBlobsVersion}
        tipCopy={tipCopy && <ModuleMarkdown>{tipCopy.childMarkdownRemark.htmlAst}</ModuleMarkdown>}
        tipTitle={tipTitle}
        title={title}
        videoMp4={videoUrl}
        videoPoster={videoPoster && videoPoster.fluid.srcWebp}
      />
    </div>
  );
}

export const ProTipDataPropsObject = {
  copy : PropTypes.shape({
    childMarkdownRemark : PropTypes.shape({
      htmlAst : PropTypes.object
    })
  }).isRequired,
  ctaCopy          : PropTypes.string,
  ctaTrackingClass : PropTypes.string,
  ctaUrl           : PropTypes.string,
  hasMarginBottom  : PropTypes.bool,
  hideAssetBlobs   : PropTypes.bool,
  hideTipBlobs     : PropTypes.bool,
  imageAlt         : PropTypes.string,
  imageSrcFluid    : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number,
      sizes       : PropTypes.string,
      src         : PropTypes.string,
      srcSet      : PropTypes.string,
      srcSetWebp  : PropTypes.string,
      srcWebp     : PropTypes.string
    })
  }),
  mobileCTACopy          : PropTypes.string,
  mobileCTATrackingClass : PropTypes.string,
  mobileCTAUrl           : PropTypes.string,
  tagline                : PropTypes.string,
  tipCopy                : PropTypes.shape({
    childMarkdownRemark : PropTypes.shape({
      htmlAst : PropTypes.object
    })
  }).isRequired,
  tipTitle    : PropTypes.string,
  title       : PropTypes.string.isRequired,
  videoPoster : PropTypes.shape({
    fluid : PropTypes.shape({
      srcWebp : PropTypes.string
    })
  }),
  videoUrl : PropTypes.string
};

ProTipModule.propTypes = {
  blobs           : PropTypes.node,
  className       : PropTypes.string,
  data            : PropTypes.shape(ProTipDataPropsObject).isRequired,
  tipBlobsVersion : PropTypes.number
};

ProTipModule.defaultProps = {
  blobs           : null,
  className       : null,
  tipBlobsVersion : null
};

export const ProTipModuleData = graphql`
  fragment ProTipModuleData on ContentfulComponentProTip {
    copy {
      childMarkdownRemark {
        htmlAst
      }
    }
    ctaCopy
    ctaTrackingClass
    ctaUrl
    hasMarginBottom
    hideAssetBlobs,
    hideTipBlobs,
    imageAlt
    imageSrcFluid {
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    mobileCTACopy
    mobileCTATrackingClass
    mobileCTAUrl
    tipCopy {
      childMarkdownRemark {
        htmlAst
      }
    }
    tipTitle
    title
    tagline
    videoPoster {
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    videoUrl
  }
`;
